/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FC, useContext, useEffect, useState } from 'react'
import { parseHash, toSnakeCase } from '../utils/helpers'
import { Button } from 'primereact/button'
import Keycloak from 'keycloak-js'
import { useApiRepository } from '../hooks/useApiRepository'
import { AuthContext } from '../hooks/useAuthProvider'

export const KeycloakButton: FC<{
  name?: string
  icon?: string
  options: any
  tenant: string
}> = ({ name, icon, options, tenant }) => {
  const [keycloak, setKeycloak] = useState(new Keycloak())
  const { isAuthenticated, login, logout } = useContext(AuthContext)
  const { api } = useApiRepository()
  useEffect(() => {
    if (!tenant) {
      return
    }
    setKeycloak(
      new Keycloak({
        realm: tenant,
        ...(options || {}),
      }),
    )
  }, [tenant, options])
  useEffect(() => {
    const query = parseHash(new URL(window.location.href).hash)
    if (['code', 'access_token', 'accessToken'].some((e) => e in query)) {
      (!isAuthenticated && 'loginByToken' in api && typeof api.loginByToken === 'function') &&
        api
          .loginByToken({
            ...Object.entries(query).reduce((acc, [key, val]) => {
              acc[toSnakeCase(key)] = val
              return acc
            }, {} as Record<string, string | boolean>),
            redirect_uri: options.redirectUri || 'http://localhost:9000/login',
          })
          .then(() => {
            login()
            setTimeout(() => window.location.assign('/'))
          })
          .catch((error: Error) => {
            console.error(error)
            logout()
          })
    }
  }, [])
  return (
    <Button
      label={name}
      // tooltipOptions={{ position: 'bottom' }}
      type="button"
      icon={`mdi mdi-24px ${icon || 'mdi-code-not-equal-variant'}`}
      css={css`
        & {
          width: initial !important;
        }
      `}
      onClick={() => {
        keycloak.init({
          flow: 'implicit',
          onLoad: 'login-required',
          checkLoginIframe: false,
          redirectUri: 'http://localhost:9000/login',
          ...(options || {}),
        })
      }}
      onDoubleClick={() => {
        keycloak.logout()
      }}
      className={'p-button-rounded p-button-outlined p-button-secondary'}
    />
  )
}
