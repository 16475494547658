import { LocaleCodes } from '../hooks/useLocale';
import { localesRU } from './locales.ru';
import { localesEN } from './locales.en';

type TDefaultLocale = Record<LocaleCodes, {[index: string]: unknown}>

export const locales: TDefaultLocale = {
  ru: localesRU,
  en: localesEN
}

export type ILocale = typeof locales;
export type TFormLocaleKeys = keyof typeof localesRU.formLocale;
