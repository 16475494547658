/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {FC} from 'react';

// language=SCSS
const s = {
  fillText: css`
    & {
      fill: var(--logo-text-color);
    }
  `,
  fillTextSecondary: css`
    & {
      fill: var(--logo-text-color-secondary);
    }
  `,
  fillPrimary: css`
    & {
      fill: var(--logo-primary-color);
    }
  `,
  strokeOutline: css`
    & {
      stroke: var(--logo-outline-color);
    }
  `,
  fillOutline: css`
    & {
      fill: var(--logo-outline-color);
    }
  `
};

export const LogoSVG: FC<JSX.IntrinsicElements['svg'] & {secondary?: boolean}> = ({secondary = false, ...rest}) => {
  return (
    <svg width="112" height="20" viewBox="0 0 112 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M94.9141 4.57715V16.6771V16.8342H98.5912V10.6428L102.394 16.8342H106.763V4.57715H103.085V10.8314L99.2512 4.57715H94.9141Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        d="M6.22266 4.57758V16.6775V16.8347H9.89978V10.6433L13.7026 16.8347H18.0712V4.57758H14.394V10.8318L10.5598 4.57758H6.22266Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        d="M29.8875 4.57716V7.75142H23.2876V9.32284H29.2904V11.9628H23.2876V13.5028H29.8875V16.8342H19.4219V4.57715L29.8875 4.57716Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        d="M30.6738 7.78285V4.57715H42.0509V7.78285H38.2795V16.8342H34.4452V7.78285H30.6738Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        d="M46.9538 16.8342L42.3652 4.57715H46.3252L48.5881 10.9257H49.5309L51.7309 4.57715H55.7537L51.1652 16.8342H46.9538Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        d="M60.3424 4.57715H56.4453V16.8342H60.3424V4.57715Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        d="M78.3815 4.57715H74.4844V16.8342H78.3815V4.57715Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.7258 7.09142C61.7258 5.70283 62.8515 4.57715 64.2401 4.57715H72.7257V7.78285H65.4962V9.35414H61.7248V9.13985V7.78271H61.7258V7.09142ZM69.5193 13.5026V12.3086H64.2391C62.8505 12.3086 61.7248 11.1829 61.7248 9.79432V9.35432H70.6505V9.35449H70.776C72.1646 9.35449 73.2903 10.4802 73.2903 11.8688V12.3086H73.2907V13.6914H73.2903V14.3197C73.2903 15.7083 72.1646 16.834 70.776 16.834H61.7246V13.5026H69.5193Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.9141 15.0287V16.6776V16.8347H98.5912V15.6026C97.3604 15.4038 96.1348 15.2125 94.9141 15.0287ZM101.984 16.1673L102.394 16.8347H105.765C104.499 16.6047 103.239 16.3822 101.984 16.1673Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.4844 12.6323V16.8346H78.3815V12.9795C77.0789 12.8544 75.78 12.7387 74.4844 12.6323Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9137 11.9323C63.2985 12.1714 63.7527 12.3096 64.2391 12.3096H69.5193V13.5039V13.504H61.7246V16.8355H70.776C72.1646 16.8355 73.2903 15.7098 73.2903 14.3212V13.6924H73.2907V12.5374C69.8123 12.2671 66.3565 12.065 62.9137 11.9323Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4453 11.7646V16.8352H60.3424V11.846C59.042 11.8089 57.7432 11.7817 56.4453 11.7646Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0957 11.873L46.9533 16.8352H51.1647L53.0711 11.7429C50.4118 11.7436 47.7548 11.7868 45.0957 11.873Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4453 12.4476V16.8347H38.2796V12.1877C37.003 12.2644 35.7251 12.351 34.4453 12.4476Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4219 14.0309V16.8347H29.8875V13.5033L23.5839 13.5033C22.2004 13.6675 20.8133 13.8433 19.4219 14.0309Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22266 16.1375V16.6781V16.8352H9.89978V15.4861C8.67841 15.6943 7.45283 15.9114 6.22266 16.1375ZM12.5995 15.0392L13.7026 16.8352H18.0712V14.2166C16.2553 14.4708 14.4318 14.7449 12.5995 15.0392Z"
        fill="#00A3FF"
        css={s.fillPrimary}
      />
      <path
        d="M0 17.6128C23.6408 9.37885 79.2219 7.29014 111.508 17.9029C71.8656 10.7713 38.4478 10.2492 0 17.6128Z"
        fill="white"
        css={secondary ? s.fillTextSecondary : s.fillText}
      />
      <circle
        cx="86.3652"
        cy="10.4228"
        r="8.17139"
        fill="#00A3FF"
        stroke="black"
        strokeWidth="0.502854"
        css={[s.fillPrimary, s.strokeOutline]}
      />
      <circle cx="86.365" cy="10.4226" r="2.70284" fill="black" css={s.fillOutline} />
      <circle cx="88.2504" cy="8.6" r="1.44571" fill="white" />
      <circle cx="90.2621" cy="6.39979" r="0.754282" fill="white" />
    </svg>
  );
};
