export const localesEN = {
  commonLocale: {
    header: 'Login',
    techSupport: 'Netvision technical support'
  },
  formLocale: {
    username: 'User name',
    password: 'Password',
    service: 'Organization',
    useDefaultService: 'Organization by default',
    submit: 'Enter',
    enterUsername: 'Enter the user name',
    enterPassword: 'Enter the password',
    enterService: 'Enter the organization name',
    loading: 'Authorization...',
    success: 'Successful authorization',
    wrong_data: 'Incorrect data',
    server_error: 'Server error',
    unexpected_error: 'Unexpected error'
  },
  licenseLocale: {
    expired: 'The license is not valid or has expired. Contact technical support',
    expiring: 'Days left before the license expires: {param}'
  }
}
