import { Theme, useTheme } from './useTheme';
import { useEffect, useState } from 'react';

export const useLazyImage = (init: Partial<Record<Theme, () => Promise<string>>>) => {
  const theme = useTheme();
  const [bgImage, setBgImage] = useState<string | undefined>(undefined);
  useEffect(() => {
    let mounted = true;
    const f = init[theme];
    if (f) {
      f().then((url) => {
        if (mounted) {
          setBgImage(url);
        }
      });
    } else {
      setBgImage(undefined);
    }
    return () => {
      mounted = false;
    };
  }, [theme, init]);
  return bgImage;
};
