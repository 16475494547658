/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {Fragment, useEffect, useRef, useState, MutableRefObject} from 'react';
import {Logo} from './Logo';
import {useLazyImage} from '../hooks/useLazyImage';
import {Theme} from '../hooks/useTheme';

export type RenderNode = unknown;

interface Props {
  areas: [
    {
      name: 'protected';
      children: Array<RenderNode>;
    }
  ];
  mountChildren: (domElement: HTMLElement, children: Array<RenderNode>) => () => void;
  showLogoProp: boolean;
}

export default function Protected({mountChildren, areas, showLogoProp}: Props) {
  const bgImage = useLazyImage({[Theme.dark]: () => import('../assets/dark-bg.jpg').then((res) => res.default)});
  const mountingPointRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [showLogo, setShowLogo] = useState(true);
  useEffect(() => {
    const stack: number[] = [];
    const onHide = () => {
      stack.push(1);
      setShowLogo(false);
    };
    const onShow = () => {
      stack.pop();
      if (stack.length === 0) {
        setShowLogo(true);
      }
    };
    window.addEventListener('nv-hide-logo', onHide);
    window.addEventListener('nv-show-logo', onShow);
    return () => {
      window.removeEventListener('nv-hide-logo', onHide);
      window.removeEventListener('nv-show-logo', onShow);
    };
  }, []);
  useEffect(() => {
    const {current: mountingPoint} = mountingPointRef;
    if (mountingPoint) {
      const children = areas.find((area) => area.name === 'protected')?.children;
      if (children) {
        return mountChildren(mountingPoint, children);
      }
    }
    return undefined;
  }, [mountChildren, areas, showLogoProp]);
  return (
    <Fragment>
      <div css={bgCss} style={bgImage ? {backgroundImage: `url('${bgImage}')`} : undefined} />
      <div ref={mountingPointRef} />
      {showLogo && showLogoProp && <Logo secondary small css={logoPos} />}
    </Fragment>
  );
}

// language=SCSS
const bgCss = css`
  & {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    background-color: var(--body-bg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

// language=SCSS
const logoPos = css`
  & {
    position: absolute;
    bottom: var(--spacer);
    right: var(--spacer);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;
