export const parseHash = (hash: string): Record<string, string | boolean> =>
  typeof hash === 'string' && hash
    ? hash
        .replace(/^#/, '')
        .split('&')
        .reduce((acc, field) => {
          const [key, value] = field.split('=')
          if (value) {
            acc[key] = value
          } else {
            acc[key] = true
          }
          return acc
        }, {} as Record<string, string | boolean>)
    : {}
export const toSnakeCase = (s: string) =>
  s
    .replace(/(?:^|\.?)([A-Z])/g, (_, y) => '_' + y.toLowerCase())
    .replace(/^_/, '')
