/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { CSSProperties, FC } from 'react'
import { LogoSVG } from './LogoSVG'

export const Logo: FC<{
  className?: string
  style?: CSSProperties
  secondary?: boolean
  small?: boolean
  subLabel?: string
}> = ({ className, style, secondary, small, subLabel }) => {
  return (
    <div className={`p-d-flex p-flex-column p-ai-center ${className}`}>
      <div css={$logo} style={style}>
        <LogoSVG secondary={secondary} css={[!small && $big]} />
      </div>
      {subLabel && <div css={$subLabel}>{subLabel}</div>}
    </div>
  )
}

// language=SCSS
const $logo = css`
  & {
    background: var(--input-bg-filled);
    box-shadow: var(--shadow-raised);
    backdrop-filter: blur(15px);
    box-sizing: content-box;
    padding: var(--logo-padding-top) var(--logo-padding-left);
    width: min-content;
    border-radius: var(--border-radius);
    box-shadow: 0px 10px 8px 0px rgba(var(--bg-raw), 0.38);
    > svg {
      display: block;
    }
  }
`
const $subLabel = css`
  & {
    padding: calc(4rem / var(--bfs)) var(--spacer-xs);
    background-color: var(--text-color);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    overflow: hidden;
    color: var(--bg);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
`
const $big = css`
  & {
    width: calc(175rem / var(--bfs));
    height: calc(32rem / var(--bfs));
  }
`
